<!--数据分析课程列表-->
<template>
  <div class="dataAnalysisList">
    <el-form :inline="true" class="form-inline" @submit.native.prevent>
      <el-form-item>
        <el-select v-model="post.live_mode">
          <el-option v-for="item in courseCateGory1" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-right:">
        <el-select v-model="post.live_status">
          <el-option v-for="item in courseCateGory2" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input class="searchInput" v-model.trim="post.name" :placeholder="'输入章节名称搜索'"></el-input>
      </el-form-item>
    </el-form>
    <!--分页-->
    <pagination2 :option="post" url="/course/analysisList" @complete="loading = false">
      <template v-slot:default="{ tableData }">
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333'
          }"
        >
          <el-table-column label="章节名称" prop="name"></el-table-column>
          <el-table-column label="开始时间">
            <template slot-scope="scope">
              {{ scope.row.startTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="结束时间">
            <template slot-scope="scope">
              {{ scope.row.endTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="live_status">
            <template slot-scope="scope">
              {{ scope.row.live_status | getLiveStatus }}
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="live_mode">
            <template slot-scope="scope">
              {{ scope.row.live_mode | getLiveMode }}
            </template>
          </el-table-column>
          <el-table-column label="回放数量" prop="playback_count">
            <template slot-scope="scope">
              {{ scope.row.playback_count || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="上课老师" prop="teacher_name"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <div class="btns">
                <div @click="toDetail(scope.row)" class="toDetail">
                  统计详情
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
    <loading v-show="loading" leftPos="0"></loading>
  </div>
</template>
<script>
export default {
  name: 'dataAnalysisList',

  data() {
    return {
      loading: true,
      post: {
        live_mode: 1,
        live_status: 1,
        course_type: this.$route.query.course_type,
        course_id: this.$route.query.course_id,
        name: ''
      },
      courseCateGory1: [
        {
          name: '全部类型',
          id: 1
        },
        {
          name: '普通模式',
          id: 2
        },
        {
          name: '极速模式',
          id: 3
        }
      ],
      courseCateGory2: [
        {
          name: '全部状态',
          id: 1
        },
        {
          name: '已结束',
          id: 2
        },
        {
          name: '未开始',
          id: 3
        },
        {
          name: '直播中',
          id: 4
        }
      ]
    }
  },

  filters: {
    getLiveStatus(value) {
      let text
      switch (Number(value)) {
        case 2:
          text = '已结束'
          break
        case 3:
          text = '未开始'
          break
        case 4:
          text = '直播中'
          break
        default:
          text = '全部'
      }
      return text
    },

    getLiveMode(value) {
      let text
      switch (Number(value)) {
        case 2:
          text = '极速模式'
          break
        default:
          text = '普通模式'
          break
      }
      return text
    }
  },

  methods: {
    toDetail({ sl_id, live_mode }) {
      // 未开始
      // if (live_status == 3) {
      //   this.$confirm('直播未开始，暂不可查看统计详情！', '提示', {
      //     confirmButtonText: '我知道了',
      //     showCancelButton: false
      //   })
      // } else {
      //   this.$router.push(`EditCourse/dataAnalysisDetail?sl_id=${sl_id}&type=${live_mode == 2 ? 5 : this.$route.query.course_type}`)
      // }
      this.$router.push(`EditCourse/dataAnalysisDetail?sl_id=${sl_id}&type=${live_mode == 2 ? 5 : this.$route.query.course_type}`)
    }
  }
}
</script>
<style lang="scss" scoped>
.dataAnalysisList {
  padding-right: 20px;
}
.toDetail {
  font-size: 14px;
  color: $color;
  line-height: 19px;
  text-decoration: none;
  cursor: pointer;
}
::v-deep .form-inline {
  font-size: 14px;
  text-align: right;
  .el-input--suffix {
    width: 120px;
    margin-right: 20px;
  }
  .searchInput {
    width: 150px;
  }
  .el-form-item {
    margin-right: 0;
  }
}
</style>
